// src/hooks/useAuth.js

import {  useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setisverify, setuseremail, setuserid, setusername, setuservip } from '../store/modules/userinfomodule';

const useAuth = () => {
  const dispatch = useDispatch(); // 用于派发 Redux 操作

  // 在组件加载时检查 token 并验证
  useEffect(() => {
    const token = localStorage.getItem('authToken'); // 从 localStorage 获取 JWT

    if (token) {
      // 如果有 JWT，进行验证
      verifyToken(token);
    } else {
      dispatch(setisverify(false))
    }
  }, []);

  // 验证 JWT 的函数
  const verifyToken = async (token) => {
    try {
      const response = await fetch('https://api.music.coderyy.site/verifyToken.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // 将 JWT 作为 Authorization 头部传给后端
        },
      });

      const data = await response.json();

      if (data.status === 'success') {
        // JWT 验证成功，设置用户数据
        dispatch(setisverify(true))
        dispatch(setusername(data.user.username))
        dispatch(setuseremail(data.user.email))
        dispatch(setuservip(data.user.vip))
        dispatch(setuserid(data.user.id)); // 将用户信息存入 Redux
      } else {
        // JWT 无效，清除 JWT 并更新状态
        localStorage.removeItem('authToken');
        dispatch(setisverify(false))
      }
    } catch (error) {
      console.error('验证失败', error);
      localStorage.removeItem('authToken');
      dispatch(setisverify(false))
    }
  };

};

export default useAuth;
