import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { setcoversrc, setisplaying, setlrcname, setmusicauthor, setmusicduration, setmusicname, setmusicsrc } from '../store/modules/audioinfomodule';

export default function Audioinfo({ props }) {
  const { id, musicsrc,coversrc, musicname, musicauthor, album, isFavorite, musicduration,lrcname } = props;
  const dispatch =useDispatch()
  const [showOptions, setShowOptions] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const isplayingmusic = useSelector((state) => state.audioinfo.musicsrc);
  const handlePlay = () => {
      if(musicsrc===isplayingmusic){
        return;
      }
      dispatch(setmusicsrc(musicsrc))
      dispatch(setmusicname(musicname))
      dispatch(setmusicauthor(musicauthor))
      dispatch(setcoversrc(coversrc))
      dispatch(setmusicduration(musicduration))
      dispatch(setisplaying(musicsrc))
      dispatch(setlrcname(lrcname))
  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
    setShowMoreOptions(false);
  };

  return (
    <li
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderBottom: '1px solid #f0f0f0',
        backgroundColor: '#fff',
        fontSize: '14px',
        color: '#333',
        position: 'relative'
      }}
    >
      {/* 编号 */}
      <span style={{ width: '30px', textAlign: 'center', color: '#888' }}>
        {id < 10 ? `0${id}` : id}
      </span>

      {/* 播放按钮和封面图 */}
      <div style={{ display: 'flex', alignItems: 'center', width: '50px' }}>
        <button
          onClick={()=>handlePlay()}
          style={{
            width: '24px',
            height: '24px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          ▶️
        </button>
        <img
          src={coversrc}
          alt={musicname}
          style={{ width: '40px', height: '40px', borderRadius: '5px', marginLeft: '10px' }}
        />
      </div>

      {/* 标题和歌手 */}
      <div style={{ flex: 2, paddingLeft: '46px' }}>
        <div style={{ fontWeight: 'bold' }}>{musicname}</div>
        <div style={{ color: '#888' }}>{musicauthor}</div>
      </div>

      {/* 专辑（稍微往中间靠） */}
      <div style={{ flex: 1, color: '#888', textAlign: 'center', paddingLeft: '50px' }}>
        {album}
      </div>

      {/* 收藏图标 */}
      <div style={{ width: '30px', textAlign: 'center', cursor: 'pointer' }}>
        {isFavorite ? '❤️' : '🤍'}
      </div>

      {/* 时长 */}
      <div style={{ width: '50px', textAlign: 'center', color: '#888' }}>{musicduration}</div>

      {/* 功能区（鼠标悬停显示，放置在中间） */}
      {showOptions && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            gap: '10px',
            backgroundColor: '#fff',
            padding: '5px 10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            zindex: 10
          }}
        >
          <span style={{ cursor: 'pointer' }}>❤️</span> {/* 收藏 */}
          <span style={{ cursor: 'pointer' }}>⬇️</span> {/* 下载 */}
          <span style={{ cursor: 'pointer' }}>💬</span> {/* 评论 */}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setShowMoreOptions(!showMoreOptions)}
          >
            ⋮
          </span>

          {/* 更多功能菜单 */}
          {showMoreOptions && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#fff',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                borderRadius: '8px',
                marginTop: '5px',
                zindex: 20
              }}
            >
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>分享</div>
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>添加到播放列表</div>
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>查看艺术家</div>
              {/* 可以根据需要添加更多功能 */}
            </div>
          )}
        </div>
      )}
    </li>
  );
}
