import App from "./App";
import ReactDOM from 'react-dom/client'
import './css/reset.css'
import { Provider } from "react-redux";
import store from "./store";

const root=ReactDOM.createRoot(document.getElementById('app'))
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
)