import React, { useState } from 'react';
import { FaVolumeUp } from 'react-icons/fa';

const VolumeControl = ({audio}) => {
  const [volume, setVolume] = useState(0.18); // 初始音量
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  const handleVolumeChange = (event) => {
    setVolume(event.target.value)
    audio.volume=event.target.value
  }
  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {/* 鼠标悬停时显示音量滑块 */}
      <FaVolumeUp
        style={{ fontSize: '24px', cursor: 'pointer' }}
        onMouseEnter={() => setShowVolumeSlider(true)}
      />

      {/* 控制音量滑块显示和隐藏 */}
      {showVolumeSlider && (
        <div
          style={styles.volumeSliderContainer}
          onMouseEnter={() => setShowVolumeSlider(true)} // 鼠标悬停在滑块上时保持显示
          onMouseLeave={() => setShowVolumeSlider(false)} // 鼠标离开滑块时隐藏
        >
          <input
            type="range"
            min="0"
            max="1"
            value={volume}
            onChange={handleVolumeChange}
            style={styles.volumeSlider}
            step="0.01"
          />
          <span style={styles.volumeLabel}>{Math.round(volume * 100)}%</span>
        </div>
      )}
    </div>
  );
};

const styles = {
  volumeSliderContainer: {
    position: 'absolute',
    bottom: '40px',
    left: '-10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  volumeSlider: {
    writingMode: 'vertical-lr', 
    direction: 'rtl',
    width: '8px',
    height: '100px',
    background: 'linear-gradient(to top, red, #ddd)',
    borderRadius: '5px',
    outline: 'none',
  },
  volumeLabel: {
    marginTop: '5px',
    fontSize: '14px',
    color: '#333',
  },
};

export default VolumeControl;
