import { useLocation } from 'react-router-dom'
import Asidebutton from './Asidebutton'
import { useEffect, useState } from 'react'

const asidelist=[
    {id:'1',content:'推荐',href:'/recommend'},
    {id:'2',src:'',content:'精选',href:'specialselect'},
    {id:'3',src:'',content:'博客',href:'boke'},
    {id:'4',src:'',content:'漫游',href:'manyou'},
    {id:'5',src:'',content:'我喜欢的音乐',href:'/myfavorite'},
    {id:'6',src:'',content:'最近播放',href:'/recentplay'},
    {id:'7',src:'',content:'我的博客',href:"/myboke"},
    {id:'8',src:'',content:'我的收藏',href:'mycollect'},
]

function Aside(){
    const location=useLocation()
    const [active,setactive]=useState()
    useEffect(()=>{
        if(location.pathname==='/recommend'){
            setactive('1')
        }
        if(location.pathname==='/specialselect'){
            setactive('2')
        }
        if(location.pathname==='/boke'){
            setactive('3')
        }
        if(location.pathname==='/manyou'){
            setactive('4')
        }
        if(location.pathname==='/myfavorite'||location.pathname==='/'){
            setactive('5')
        }
        if(location.pathname==='/recentplay'){
            setactive('6')
        }
        if(location.pathname==='/myboke'){
            setactive('7')
        }
        if(location.pathname==='/mycollect'){
            setactive('8')
        }
        if(location.pathname==='/mine'){
            setactive('0')
        }
    },[location.pathname])
    const style ={
        zIndex: '100'
    }
    return(
        <div style={{width:'229px',backgroundColor:'#f4f4f4',
            borderRight: '1px solid #ccc',
            position: 'fixed',
            top: '0',
            bottom: '55px'
        }}>
            <div style={{display:'flex',marginBottom:'18px',padding:'26px',lineHeight:'36px',justifyContent:'center'}}>
                <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/logo.png" alt="" style={{width:'36px',height:'36px'}} />
                <a  href="https://music.coderyy.site" style={{marginLeft:'18px'}}>coderyy's music</a>
            </div>
            <div style={style}>
                <ul style={{
                    display : 'flex',
                    flexDirection:'column',
                    gap:'10px',
                    backgroundColor:'rgb(240,243,246)',
                    color:'rgba(120,127,141,255)',
                    width:'180px'
                }}>
                    {asidelist.map(item => (
                    <li key={item.id} style={{borderRadius:'10px',paddingLeft:'48px',height:'40px',lineHeight:'40px', backgroundColor: item.id===active ? 'rgba(253,62,74,255)' :'rgba(240, 243, 246, 0.5)'}}>
                        <Asidebutton props={item} />
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Aside