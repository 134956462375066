import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"


function Asidebutton({props}){
    const location=useLocation()
    const [active,setactive]=useState('')
    useEffect(()=>{
        if(location.pathname==='/recommend'||location.pathname==='/'){
            setactive('1')
        }
        if(location.pathname==='/specialselect'){
            setactive('2')
        }
        if(location.pathname==='/boke'){
            setactive('3')
        }
        if(location.pathname==='/manyou'){
            setactive('4')
        }
        if(location.pathname==='/myfavorite'||location.pathname==='/'){
            setactive('5')
        }
        if(location.pathname==='/recentplay'){
            setactive('6')
        }
        if(location.pathname==='/myboke'){
            setactive('7')
        }
        if(location.pathname==='/mycollect'){
            setactive('8')
        }
        if(location.pathname==='/mine'){
            setactive('0')
        }
    },[location.pathname])
    return (
        <div>
            <Link to={props.href} style={{ color: props.id===active ? 'white':'rgba(120,127,141,255)',lineHeight:'36px'}}>              
                <img src={props.src} alt="" />
                {props.content}
            </Link>
         </div>
    )
}

export default Asidebutton