export default function SearchBar() {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '400px',
            position: 'relative',
            boxSizing: 'border-box'
        }}>

            {/* 搜索框部分 */}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                padding: '5px 15px',
                width: '60%',
                height:'30px',
                position: 'absolute',
                left: '49px',            
                boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
            }}>
                <input
                    type="text"
                    placeholder=""
                    style={{
                        flexGrow: '1',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        color: '#666',
                        fontSize: '14px',
                        marginLeft: '10px'
                    }}
                />
            </div>
        </div>
    );
}
