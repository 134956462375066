import React, { useState, useRef, useEffect } from 'react';
import { FaHeart, FaStepBackward, FaPlay, FaPause, FaStepForward, FaSync } from 'react-icons/fa';
import Volumecontrol from './Volumecontrol';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { setmusicduration, fetchLyrics } from '../store/modules/audioinfomodule';

export default function AudioPlayer() {
    const audioRef = useRef(null);
    const dispatch = useDispatch();
    const musicsrc = useSelector(state => state.audioinfo.musicsrc);
    const musicname = useSelector(state => state.audioinfo.musicname);
    const lrcname = useSelector(state => state.audioinfo.lrcname);
    const lyrics = useSelector(state => state.audioinfo.lyrics);
    const musicauthor = useSelector(state => state.audioinfo.musicauthor);
    const coversrc = useSelector(state => state.audioinfo.coversrc);
    const musicduration = useSelector(state => state.audioinfo.musicduration);
    const lyricsContainerRef = useRef(null);
    const isplaying = useSelector(state => state.audioinfo.isplaying);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [showLyrics, setShowLyrics] = useState(false);
    const [currentLyricIndex, setCurrentLyricIndex] = useState(-1);
    const [showQualityOptions, setShowQualityOptions] = useState(false);
    const [showSoundEffectOptions, setShowSoundEffectOptions] = useState(false);
    const [hasUserScrolled, setHasUserScrolled] = useState(false); // 用户手动滚动歌词状态

    useEffect(() => {
        if (isplaying) {
            audioRef.current.load();
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isplaying]);

    // 获取歌词数据
    useEffect(() => {
        if (lrcname) {
            dispatch(fetchLyrics(lrcname));
        }
    }, [lrcname, dispatch]);

    useEffect(() => {
        const audio = audioRef.current;
        const handleLoadedMetadata = () => dispatch(setmusicduration(audio.duration));
        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime); // 更新 currentTime
            updateCurrentLyricIndex(audio.currentTime); // 更新歌词
        };

        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('play', () => setIsPlaying(true));
        audio.addEventListener('pause', () => setIsPlaying(false));

        return () => {
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);// eslint-disable-line

    const updateCurrentLyricIndex = (currentTimeInSeconds) => {
        if (Array.isArray(lyrics) && lyrics.length > 0) {
            const index = lyrics.findIndex((lyric, idx) => {
                // 确保 lyric.time 是字符串类型
                if (typeof lyric.time !== 'string') {
                    console.error('Invalid time format:', lyric.time);
                    return false;
                }
    
                // 解析时间
                const [minutes, secondsWithMilliseconds] = lyric.time.split(':');
                const [seconds, milliseconds = 0] = secondsWithMilliseconds.split('.').map(Number);
    
                const lyricTimeInSeconds = Number(minutes) * 60 + seconds + (milliseconds / 1000);
    
                // 计算下一个歌词的时间
                const nextLyricTimeInSeconds = idx + 1 < lyrics.length
                    ? (() => {
                        const [nextMinutes, nextSecondsWithMilliseconds] = lyrics[idx + 1].time.split(':');
                        const [nextSeconds, nextMilliseconds = 0] = nextSecondsWithMilliseconds.split('.').map(Number);
                        return Number(nextMinutes) * 60 + nextSeconds + (nextMilliseconds / 1000);
                      })()
                    : Infinity;
    
                return lyricTimeInSeconds <= currentTimeInSeconds && currentTimeInSeconds < nextLyricTimeInSeconds;
            });
            setCurrentLyricIndex(index);
        }
    };
    
    
    
    

    useEffect(() => {
        updateCurrentLyricIndex(currentTime); // 每次进度条变化时，更新当前歌词
    }, [currentTime]);// eslint-disable-line


    useEffect(() => {
        if (!hasUserScrolled && lyricsContainerRef.current && currentLyricIndex !== -1) {
            const lyricsBox = lyricsContainerRef.current;
            const currentLyricElement = lyricsBox.children[currentLyricIndex];

            if (currentLyricElement) {
                const elementHeight = currentLyricElement.offsetHeight;
                const containerHeight = lyricsBox.offsetHeight;

                // 计算滚动位置，使得当前歌词位于容器的中央
                const offsetTop = currentLyricElement.offsetTop;
                const scrollToPosition = offsetTop - (containerHeight / 2) + (elementHeight / 2);

                // 自动滚动到当前歌词位置，使其在容器中间
                lyricsBox.scrollTo({
                    top: Math.max(scrollToPosition, 0), // 确保不滚动到负值
                    behavior: 'smooth',
                });
            }
        }
    }, [currentLyricIndex, hasUserScrolled, lyrics]);

    // 处理用户手动滚动
    const handleScroll = () => {
        setHasUserScrolled(true); // 用户手动滚动，禁用自动滚动
    };

    useEffect(() => {
        // 自动重置 hasUserScrolled 为 false，在一定时间后恢复自动滚动
        const resetScroll = setTimeout(() => {
            setHasUserScrolled(false); // 一段时间后恢复自动滚动
        }, 3000); // 设置为3秒，可以根据需要调整

        return () => clearTimeout(resetScroll);
    }, [hasUserScrolled]);

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleProgressClick = (e) => {
        const progressBar = e.currentTarget;
        const clickPosition = e.nativeEvent.offsetX;
        const progressBarWidth = progressBar.offsetWidth;
        const newTime = (clickPosition / progressBarWidth) * musicduration;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime); 
        setHasUserScrolled(false);
        updateCurrentLyricIndex(newTime); // 更新歌词
    };

    return (
        <div style={styles.container}>
            <audio ref={audioRef} src={musicsrc} />
            {/* 左侧歌曲信息 */}
            <div style={styles.songInfo}>
                <img src={coversrc} alt="Song Thumbnail" style={styles.songImage} />
                <span style={styles.songName}>
                    {musicname} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{musicauthor}
                </span>
            </div>

            {/* 中间控制按钮和进度条 */}
            <div style={styles.controlsContainer}>

                <div style={styles.controls}>
                    <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/like.png" style={styles.icon} alt="喜欢" />
                    <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/back.png" style={styles.icon} alt="上一首"/>
                    <div onClick={togglePlayPause} style={styles.playPauseButton}>
                        {isPlaying ? <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/pause.png" alt='暂停' /> : <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/play.png" alt='播放'/>}
                    </div>
                    <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/next.png" alt='下一首' style={styles.icon} />
                    <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/heartmode.png" alt='心动模式' style={styles.icon} />
                </div>

                {/* 播放进度条 */}
                <div style={styles.progressContainer}>
                    <span style={styles.time}>{formatTime(currentTime)}</span>
                    <div style={styles.progressBar} onClick={handleProgressClick}>
                        <div
                            style={{
                                ...styles.progressFill,
                                width: `${(currentTime / musicduration) * 100}%`,
                            }}
                        ></div>
                    </div>
                    <span style={styles.time}>{formatTime(musicduration)}</span>
                </div>
            </div>

            {/* 右侧功能按钮 */}
            <div style={styles.rightControls}>
                <button
                    onClick={() => setShowQualityOptions(!showQualityOptions)}
                    style={styles.iconButton}
                >
                    音质
                </button>
                {showQualityOptions && (
                    <div style={styles.dropdownMenu}>
                        <div>高音质</div>
                        <div>标准音质</div>
                        <div>低音质</div>
                    </div>
                )}

                <button onClick={() => setShowLyrics(!showLyrics)} style={styles.iconButton}>
                    {showLyrics ? '隐藏歌词' : '显示歌词'}
                </button>

                <button
                    onClick={() => setShowSoundEffectOptions(!showSoundEffectOptions)}
                    style={styles.iconButton}
                >
                    音效
                </button>
                {showSoundEffectOptions && (
                    <div style={styles.dropdownMenu}>
                        <div>原声</div>
                        <div>环绕声</div>
                        <div>低音增强</div>
                    </div>
                )}

                <button style={styles.iconButton}>一起听</button>

                <Volumecontrol audio={audioRef.current} />
            </div>

            {/* 歌词显示 */}
            {showLyrics && (
                <Draggable>
    <div style={styles.lyricsContainer}>
        <button
            style={styles.closeButton}
            onClick={() => setShowLyrics(false)}
        >
            X
        </button>
        <div
            ref={lyricsContainerRef}
            style={styles.lyricsBox}
            onScroll={handleScroll}
        >
            {lyrics.map((lyric, index) => (
                <div
                    key={index}
                    style={{
                        ...styles.lyric,
                        color: index === currentLyricIndex ? '#ff4d4f' : '#888'
                    }}
                >
                    {lyric.text}
                </div>
            ))}
        </div>
    </div>
</Draggable>

            )}
        </div>
    );
}

const styles = {
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height:'91.5px',
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        boxSizing: 'border-box',
    },
    songInfo: {
        display: 'flex',
        alignItems: 'center',
        marginLeft:'24px',
        position: 'absolute',
        left: '2.3%'
    },
    songImage: {
        width: '47px',
        height: '47px',
        borderRadius: '5px',
        marginRight: '10px',
    },
    songName: {
        fontSize: '16px',
        whiteSpace: 'nowrap',
        marginLeft: '26px',
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '350px',
        marginLeft: '50px',
        position: 'absolute',
        left: '34%'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        gap: '28px'
    },
    icon: {
        width: '28px',
        height: '28px',
        cursor: 'pointer'
    },
    playPauseButton: {
        width: '45px',
        height: '45px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        marginTop: '10px',
    },
    progressBar: {
        flex: 1,
        height: '4px',
        backgroundColor: '#e0e0e0',
        borderRadius: '2px',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    progressFill: {
        height: '100%',
        backgroundColor: '#ff4d4f',
    },
    time: {
        fontSize: '12px',
        color: '#666',
    },
    rightControls: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        position: 'absolute',
        right: '3%'
    },
    iconButton: {
        fontSize: '12px',
        color: '#666',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    },
    dropdownMenu: {
        position: 'absolute',
        top: '-80px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '5px',
        fontSize: '12px',
        color: '#333',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    },
    lyricsContainer: {
        position: 'absolute',
        bottom: '100px',
        left: '39.7%',
        transform: 'translateX(-50%)',
        width: '300px',
        height: '150px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        borderRadius: '10px',
        padding: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#fff',
        fontSize: '18px',
        cursor: 'pointer',
    },
    lyricsBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'auto',
        paddingTop: '20px',
        scrollbarWidth: 'none', // FireFox 隐藏滚动条
    },
    lyric: {
        fontSize: '14px',
        margin: '5px 0',
        textAlign: 'center',
        transition: 'color 0.3s ease',
    },
};
