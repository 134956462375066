import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Recommend from './Recommend'; 
import Myfavorite from './Myfavorite';
import Sepecialselect from './Specialselect';
import Boke from './Boke';
import Manyou from './Manyou';
import Recentplay from './Recentplay';
import Myboke from './Myboke';
import Mycollect from './Mycollect';
import Mine from './Mine';
import useAuth from './hooks/useAuth';
import { useSelector } from 'react-redux';

function App() {

  useAuth(); // 使用 useAuth 钩子获取用户登录状态
  const isverify = useSelector((state) => state.userinfo.isverify);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Myfavorite />} />
          <Route path='recommend' element={<Recommend />} />
          <Route path='myfavorite' element={<Myfavorite />} />
          <Route path='specialselect' element={<Sepecialselect />} />
          <Route path='boke' element={<Boke />} />
          <Route path='manyou' element={<Manyou />} />
          <Route path='recentplay' element={<Recentplay />} />
          <Route path='myboke' element={<Myboke />} />
          <Route path='mycollect' element={<Mycollect />} />
          <Route path='mine' element={isverify ? <Mine /> : <Recommend />} />
          {/* 如果已登录，显示 Mine 页面，否则显示推荐页面 */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
