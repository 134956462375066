import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userinforeducer from './modules/userinfomodule';
import audioinfoReducer from './modules/audioinfomodule'

// 合并 reducers，确保包含 audioinfoReducer
const rootReducer = combineReducers({
    userinfo: userinforeducer,
    audioinfo: audioinfoReducer, // 添加 audioinfo reducer
});

// 配置 store
const store = configureStore({
    reducer: rootReducer,
});

export default store;
