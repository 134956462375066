import React from 'react';
import { Outlet } from 'react-router-dom';
import Aside from './asidelayout/Aside';
import AudioPlayer from './audio/Audioplayer';
import Header from './headerlayout/Header';

export default function Layout(){

    return(
        <>
            <Header/>
            <Aside/>
            <Outlet/>
            <AudioPlayer/>
        </>
    )
}