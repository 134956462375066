import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name:'userinfo',
    initialState:{
        id:null,
        username:null,
        email:null,
        vip:null,
        isverify:false
    },
    reducers:{
        setuserid(state,action){
            state.id=action.payload
        },
        setusername(state,action){
            state.username=action.payload
        },
        setuseremail(state,action){
            state.email=action.payload
        },
        setuservip(state,action){
            state.vip=action.payload
        },
        setisverify(state,action){
            state.isverify=action.payload
        }
    }
})

export const {setuserid,setuseremail,setusername,setuservip,setisverify} = userSlice.actions
export default userSlice.reducer