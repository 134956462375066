import { useEffect, useState } from 'react';
import Audioinfo from './audio/Audioinfo';

export default function Myfavorite() {
  const [musiclist, setmusiclist] = useState([]);
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(false);
  const [height, setHeight] = useState('513.5px');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // 根据浏览器类型和窗口大小设置不同的高度
    if (userAgent.includes('Edg')) {
        setHeight('488.5px')
    }
  }, []);

  async function loadMusic(pagenum) {
    setloading(true);
    try {
      const response = await fetch('https://api.music.coderyy.site/loadmyfavorite.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: pagenum,  // 传递 page 数值
        }),
      });
      const data = await response.json();

      // 合并前面的数据和新加载的音乐数据
      setmusiclist((prevlist) => [...prevlist, ...data]);
      setpage(pagenum); // 更新页码
    } catch (error) {
      console.error('加载音乐失败', error);
    }
    setloading(false);
  }

  function handleScroll(e) {
    const isBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (isBottom && !loading) {
      loadMusic(page + 1);  // 加载下一页
    }
  }

  useEffect(() => {
    loadMusic(page);  // 初次加载第一页
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <main
        onScroll={handleScroll}
        style={{
          marginTop: '60px',
          marginLeft: '229px',
          marginBottom: '91.5px',
          padding: '20px',
          overflowY: 'auto',
          flexGrow: '1',
          height: height
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h2>我喜欢的音乐</h2>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', gap: '26px' }}>
          <span style={{ width: '42.5px', color: 'lightblue',cursor:'pointer' }}>歌曲</span>
          <span style={{ width: '42.5px',cursor:'pointer' }}>评论</span>
          <span style={{ width: '55px',cursor:'pointer' }}>收藏者</span>
          <form action="">
            <input
              style={{
                marginLeft: '550px',
                padding: '8px 16px',
                border: '1px solid #d3d3d3',
                borderRadius: '20px',
              }}
              type="text"
            />
          </form>
        </div>
        <ul style={{ marginTop: '18px', height: '600px' }}>
          {musiclist.map((item, index) => (
            <Audioinfo
              props={item}
              key={index}
            />
          ))}
        </ul>
      </main>
    </>
  );
}
